import React from 'react';
import Helmet from 'react-helmet';

import ShareImage from '../images/home.jpg';

import { seoTypes } from '../types/propTypes';

const SEO = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title} | Deported Official Website</title>
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Deported" />
      <meta property="og:image" content={ShareImage} />
      <meta property="og:image:secure_url" content={ShareImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={ShareImage} />
    </Helmet>
  );
};

SEO.propTypes = seoTypes;

export default SEO;
