export const homeStrings = {
  title: 'Home',
  description: 'Official Website of Deported the movie.',
};
export const synStrings = {
  title: 'Synopsis',
  description: 'Synopsis of Deported the movie.',
};
export const creditStrings = {
  title: 'Credits',
  description: 'Credits for Deported the movie.',
};
export const orderStrings = {
  title: 'Order Now',
  description: 'Order Deported the movie.',
};
