import React, { Fragment } from 'react';
import styled from 'styled-components';

import SEO from '../components/SEO';
import { creditStrings } from '../consts/strings';
import mq from '../style/mq';

import TextSrc from '../images/credits.png';
import PosterSrc from '../images/deported-final.jpg';

import { colors } from '../consts/style';

const Text = styled.img.attrs({ src: TextSrc })`
  width: 90%;
  ${mq.tabletSmall`
    width: 100%;
  `}
`;

const Poster = styled.img.attrs({ src: PosterSrc })`
  width: 200px;
  margin-bottom: 1.2rem;
`;

const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 7.2rem;
  left: 0;
  height: calc(100% - 7.2rem);
  width: 100%;
  background: ${colors.red};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export default function Credits() {
  return (
    <Fragment>
      <SEO
        title={creditStrings.title}
        description={creditStrings.description}
      />
      <Wrapper>
        <Poster />
        <Text />
      </Wrapper>
    </Fragment>
  );
}
